/**
 * Gère l'autocompletion sur les champs input ayant l'attribut data-autocomplete
 */

(function () {
    if (php_vars.enable_autocomplete == "1") {
        //Initialisation des champs d'autocompletion
        let triggers = document.querySelectorAll('[data-autocomplete]');
        triggers.forEach(function (trigger) {
            initialize(trigger);
        });

        //Abonnements aux évènements des champs d'autocompletion
        triggers = document.querySelectorAll('[data-autocomplete]');
        triggers.forEach(function (trigger) {

            //Affichage du loader
            trigger.addEventListener('keydown', function () {
                let loader = trigger.parentNode.querySelector('.lds-spinner-core');
                if (!loader.parentNode.classList.contains('loading')) {
                    loader.parentNode.classList.add('loading');
                }
            }, true)

            //Recherche
            trigger.addEventListener('keyup', debounce(function () {
                search(trigger);
            }, 300), true);
        });
    }

    /**
     * Initialisation de l'autocompletion pour l'élément
     * @param {*} field 
     */
    function initialize(autocompleteField) {
        let classes = [];

        if (php_vars.autocomplete_default_class == "1") {
            classes.push("autocomplete-core-default");
        }
        if (php_vars.autocomplete_custom_class) {
            classes.push(php_vars.autocomplete_custom_class);
        }

        const element = autocompleteField.parentNode;
        const wrapperHTML = '<div class="autocomplete-core-results-wrapper"><div class="autocomplete-core-results"></div></div>';
        const loaderHTML = '<div class="autocomplete-core-loader"><div class="lds-spinner-core"></div></div>';
        const fieldsHTML = '<div class="autocomplete-core-fields"></div>';

        element.innerHTML += '<div class="autocomplete-core ' + classes.join(' ') + '">' + wrapperHTML + '' + loaderHTML + '' + fieldsHTML + '</div>';

        //Création des types de résultats et du loader
        let wrapper = element.querySelector('.autocomplete-core-results');
        wrapper.innerHTML += '<div class="autocomplete-core-results-type autocomplete-core-results-regions"></div>';
        wrapper.innerHTML += '<div class="autocomplete-core-results-type autocomplete-core-results-departments"></div>';
        wrapper.innerHTML += '<div class="autocomplete-core-results-type autocomplete-core-results-towns"></div>';

        //Loader
        let loader = element.querySelector('.lds-spinner-core');
        for (var i = 0; i < 12; i++) {
            loader.innerHTML += '<div></div>';
        }
        //#endregion

        //Création des champs du formulaire
        let fields = element.querySelector('.autocomplete-core-fields');
        let selectedType = autocompleteField.getAttribute('data-search-value-type');
        let selectedId = autocompleteField.getAttribute('data-search-value-id');
        fields.innerHTML += '<input type="hidden" name="type-localisation-search" id="type-localisation-search" value="' + selectedType + '"/>';
        fields.innerHTML += '<input type="hidden" name="id-localisation-search" id="id-localisation-search" value="' + selectedId + '"/>';
    }

    function search(trigger) {
        let query = trigger.value;
        let parent = trigger.parentNode;
        let loader = parent.querySelector('.lds-spinner-core');

        if (query !== '' && query.length >= 2) {
            //#region Préparation de la requête
            var xhr = new XMLHttpRequest();

            var parameters = {
                'term': trigger.value,
                'action': 'autocomplete',
                'town-search': trigger.hasAttribute('data-search-town').toString(),
                'department-search': trigger.hasAttribute('data-search-department').toString(),
                'region-search': trigger.hasAttribute('data-search-region').toString()
            };

            xhr.open('GET', php_vars.ajaxurl + formatParams(parameters), true);
            //#endregion

            xhr.send(parameters);

            //#region Traitement de la requête
            xhr.onload = function () {

                //Suppression du loader
                if (loader.parentNode.classList.contains('loading')) {
                    loader.parentNode.classList.remove('loading');
                }

                if (xhr.status >= 200 && xhr.status < 300) {
                    let response = JSON.parse(xhr.responseText);
                    let datas = response.data;
                    let regions = datas.regions;
                    let departments = datas.departments;
                    let towns = datas.towns;

                    let wrapper = parent.querySelector('.autocomplete-core-results');
                    let regionsWrapper = wrapper.querySelector('.autocomplete-core-results-regions');
                    let departmentsWrapper = wrapper.querySelector('.autocomplete-core-results-departments');
                    let townsWrapper = wrapper.querySelector('.autocomplete-core-results-towns');
                    let showWrapper = false;
                    regionsWrapper.innerHTML = '';
                    departmentsWrapper.innerHTML = '';
                    townsWrapper.innerHTML = '';

                    // #region Ajout des régions
                    if (typeof regions != "undefined") {
                        let regionsContent = '<div class="autocomplete-core-results-title">Régions</div>';

                        regions.forEach(region => {
                            regionsContent += '<div class="autocomplete-core-results-item" data-type="region" data-id="' + region.id + '">' + region.name + '</div>';
                            showWrapper = true;
                        });

                        regionsWrapper.innerHTML = regionsContent;
                    }
                    // #endregion

                    // #region Ajout des départements
                    if (typeof departments != "undefined") {
                        let departmentsContent = '<div class="autocomplete-core-results-title">Départements</div>';
                        departments.forEach(department => {
                            departmentsContent += '<div class="autocomplete-core-results-item" data-type="department" data-id="' + department.id + '"> ' + department.name + ' (' + department.code + ')</div > ';
                            showWrapper = true;
                        });

                        departmentsWrapper.innerHTML = departmentsContent;
                    }
                    // #endregion

                    // #region Ajout des villes
                    if (typeof towns != "undefined") {
                        let townsContent = '<div class="autocomplete-core-results-title">Villes</div>';
                        towns.forEach(town => {
                            townsContent += '<div class="autocomplete-core-results-item" data-type="town" data-id="' + town.id + '"> ' + town.name + ' (' + town.zipcode + ')</div > ';
                            showWrapper = true;
                        });

                        townsWrapper.innerHTML += townsContent;
                    }
                    // #endregion

                    if (!wrapper.parentNode.classList.contains('show') && showWrapper == true)
                        wrapper.parentNode.classList.add('show');

                    selectItemEvent(wrapper, trigger);

                } else {
                    console.log('The request failed!');
                }
            }
        } else {
            //Suppression du loader
            if (loader.parentNode.classList.contains('loading')) {
                loader.parentNode.classList.remove('loading');
            }
        }
    }

    function selectItemEvent(results, trigger) {
        let items = results.querySelectorAll('.autocomplete-core-results-item');
        items.forEach(item => {
            //Création des champs nécessaires pour identifier la sélection
            let typeSearch = trigger.parentNode.querySelector('#type-localisation-search');
            let idSearch = trigger.parentNode.querySelector('#id-localisation-search');


            item.addEventListener('click', function () {
                //Affichage du nom dans le champ de recherche
                trigger.value = item.innerHTML;

                //Suppression du wrapper de résultats
                results.querySelector('.autocomplete-core-results-regions').innerHTML = '';
                results.querySelector('.autocomplete-core-results-departments').innerHTML = '';
                results.querySelector('.autocomplete-core-results-towns').innerHTML = '';
                if (results.parentNode.classList.contains('show')) {
                    results.parentNode.classList.remove('show');
                }

                //Ajout des infos sélectionnées dans le formulaire
                // typeSearch = document.querySelector('#type-search');
                typeSearch.value = item.getAttribute('data-type');
                // idSearch = document.querySelector('#id-search');
                idSearch.value = item.getAttribute('data-id');

            });
        });

        return;
    }

    function formatParams(params) {
        return "?" + Object
            .keys(params)
            .map(function (key) {
                return key + "=" + encodeURIComponent(params[key])
            })
            .join("&")
    }

    function debounce(callback, delay) {
        var timer;
        return function () {
            var args = arguments;
            var context = this;
            clearTimeout(timer);
            timer = setTimeout(function () {
                callback.apply(context, args);
            }, delay)
        }
    }
})();
