(function(){
    /**
     * Désactive le bouton d'envoi du formulaire lorsqu'il a été cliqué
     */
	document.addEventListener("DOMContentLoaded", function() {
		var buttons = document.querySelectorAll('input.wpcf7-submit[type="submit"]');
		buttons.forEach(button => {
			button.addEventListener("click", function(e){
				var disabled = this.getAttribute('data-disabled');
				if (disabled && disabled == "disabled") {
					e.preventDefault();
					return false;
				} else {
					this.setAttribute('data-disabled', 'disabled');
					return true;
				}
			})
		});

		var forms = document.querySelectorAll('.wpcf7');
		forms.forEach(form => {
			form.addEventListener("wpcf7submit", function(){
				var button = this.querySelector('input.wpcf7-submit[type="submit"]');
				button.removeAttribute('data-disabled');
			}, false);
		});	
	  });
})();